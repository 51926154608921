import "@annotate-dev/shared/style.css";
import type { LinksFunction, MetaFunction } from "@remix-run/node";
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useMatches,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import * as Sentry from "@sentry/remix";
import { useEffect } from "react";
import invariant from "tiny-invariant";

import { Button } from "./components/ui/button.js";
import { EnvProvider, type EnvContext } from "./lib/env-context";
import { prepareHtmlMetaDataDescriptor } from "./lib/utils.js";

export function loader() {
  invariant(
    process.env.NODE_ENV === "production" ||
      process.env.NODE_ENV === "development",
    "process.env.NODE_ENV must be 'production' or 'development'",
  );
  invariant(process.env.ORIGIN, "process.env.ORIGIN must be defined");

  return {
    env: process.env.NODE_ENV,
    origin: process.env.ORIGIN,
  } satisfies EnvContext;
}

export const links: LinksFunction = () => {
  return [
    {
      rel: "icon",
      type: "image/png",
      sizes: "128x128",
      href: "/logo-128x128.png",
    },
  ];
};

export const meta: MetaFunction<typeof loader> = ({ data, error }) => {
  if (error) {
    return [{ title: "Annotate" }];
  }

  return prepareHtmlMetaDataDescriptor({
    baseUrl: data!.origin,
    description:
      "Create code walkthroughs that clarify code. Turn passive passers-by into engaged users for your technical product.",
    title: "Annotate | Craft walkthroughs that clarify code",
    urlPath: "",
  });
};

export const ErrorBoundary = () => {
  const error = useRouteError();

  let message;
  if (isRouteErrorResponse(error)) {
    message = error.data.message;
  } else if (error instanceof Error) {
    message = error.message;
  }

  if (!message) {
    message = "An unexpected error occurred. Please try again later.";
  }

  captureRemixErrorBoundaryError(error);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="grid h-[100dvh] place-items-center">
        <div className="rounded-md border border-tokyo-border bg-tokyo-dark-600 px-20 py-10">
          <h1 className="text-balance text-center text-4xl font-bold">
            Uh oh, something went wrong.
          </h1>
          <p className="mb-1 mt-3 max-w-[60ch] text-balance text-center text-sm">
            {message} If you need a hand, contact{" "}
            <a className="underline" href="mailto:support@annotate.dev">
              support@annotate.dev
            </a>
            .
          </p>
          <div className="mt-8 flex items-center">
            <Button asChild size="lg" className="mx-auto w-[384px]">
              <Link to="/workspaces" prefetch="intent">
                Back to my workspace
              </Link>
            </Button>
          </div>
        </div>
      </body>
    </html>
  );
};

export default function App() {
  const envData = useLoaderData<typeof loader>();

  useEffect(
    () => {
      if (envData.env === "production") {
        Sentry.init({
          dsn: "https://f9871cf9feab068d30c83e051d10e555@o4506833279320064.ingest.us.sentry.io/4506833280696320",
          tracesSampleRate: 1,
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1,

          integrations: [
            Sentry.browserTracingIntegration({
              useEffect,
              useLocation,
              useMatches,
            }),
            Sentry.replayIntegration(),
          ],
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Sentry.init must not be called more than once or else it will throw
    [],
  );

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <EnvProvider value={envData}>
          <Outlet />
        </EnvProvider>
        <ScrollRestoration />
        <Scripts />

        <script async defer src="https://sa.annotate.dev/latest.js"></script>
        <noscript>
          <img
            src="https://sa.annotate.dev/noscript.gif"
            alt=""
            referrerPolicy="no-referrer-when-downgrade"
          />
        </noscript>
      </body>
    </html>
  );
}
